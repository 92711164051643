.login-form {
  display: flex;
  flex-direction: column;
}
.login-lang {
  margin-top: 2rem;
  width: auto;
  align-self: flex-end;
}
@media screen and (max-width: 576px) {
  .login-lang {
    text-align: center;
  }
}
