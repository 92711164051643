[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  /* position: absolute; */
  opacity: 0;
  /* pointer-events: none; */
}
.option_group{margin-top: 100px;}
.option_group_shadow{box-shadow: 2px 0px 7px rgba(0,0,0,.1);}
.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  background-color: #818181;
  border-radius: 0.9375rem;
  margin-right: 0.625rem;
  vertical-align: middle;
  margin: 0 1rem;
  width: 2.5rem;
  height: 0.375rem;
}

.switch label input[type="checkbox"]:checked + .lever {
  background-color: #dccfe2;
}

.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #be0a27;
  left: 1.5rem;
}
.switch label .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  background-color: #f1f1f1;
  border-radius: 1.3125rem;
  left: -0.3125rem;
  top: -0.475rem;
  box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(0, 0, 0, 0.4);
  width: 1.3125rem;
  height: 1.3125rem;
}
.border-style {
  border: 1px solid #d1d3e2 !important;
}
.cursr {
  cursor: pointer;
}
.dashboard-icon {
  background-color: #be0a27;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
